var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ratingContainer" },
    [
      _c("img", {
        attrs: {
          src: require("@/assets/images/camplus_logo_white.png"),
          alt: "",
          height: "160"
        }
      }),
      _c("br"),
      _c("h1", { staticClass: "question" }, [
        _vm._v("Avez vous aimez la prestation ?")
      ]),
      _c("h1", { staticClass: "question" }, [_vm._v("هل اعجبتك الخدمة ؟")]),
      _c("vue-feedback-reaction", {
        staticClass: "feedback",
        attrs: { labels: _vm.labels },
        on: { input: _vm.sendFeedback },
        model: {
          value: _vm.feedback,
          callback: function($$v) {
            _vm.feedback = $$v
          },
          expression: "feedback"
        }
      }),
      _c("img", {
        staticClass: "qr",
        attrs: {
          src: require("@/assets/images/azimut_qr.png"),
          alt: "",
          height: "120"
        }
      }),
      _c("img", {
        staticClass: "sh_logo",
        attrs: {
          src: require("@/assets/images/sonatrach_logo.png"),
          alt: "",
          height: "120"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }